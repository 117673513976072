'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = setupReact;

var _reactDom = require('react-dom');

var injectEventPluginsByName = void 0;
// from https://github.com/facebook/react/blob/v16.5.1/packages/react-dom/src/client/ReactDOM.js#L750
var secret = _reactDom.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
if (secret && secret.Events && secret.Events[3]) {
  injectEventPluginsByName = secret.Events[3];
} else {
  injectEventPluginsByName = function injectEventPluginsByName() {
    console.warn('logrocket-react does not work with this version of React');
  };
}

function setupReact() {
  injectEventPluginsByName({
    ResponderEventPlugin: {
      extractEvents: function logRocketReactEventHook(topLevelType, targetInst, fiberNode, nativeEvent) {
        try {
          if (topLevelType !== 'click' || !fiberNode || !nativeEvent) {
            return;
          }

          var currentElement = fiberNode;

          var names = [];
          while (currentElement) {
            var name = typeof currentElement.elementType === 'function' && currentElement.elementType.displayName;
            if (name) {
              names.push(name);
            }
            currentElement = currentElement.return;
          }
          // eslint-disable-next-line no-param-reassign
          nativeEvent.__lrName = names;
        } catch (error) {
          console.error('logrocket-react caught an error while hooking into React. Please make sure you are using the correct version of logrocket-react for your version of react-dom.');
        }
      }
    }
  });
}
module.exports = exports['default'];