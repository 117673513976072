import React from "react";

const initialContext = {
    visibleBookmarks: undefined,
    visibleHistory: undefined,
    onHistoryExpand: (_) => _,
    onBookmarksExpand: (_) => _,
};

const navigationContext = React.createContext(initialContext);

export { navigationContext };
