import React from "react";
import App from "next/app";
import Router from "next/router";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import * as Sentry from "@sentry/browser";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";
import { v4 as uuidv4 } from "uuid";

import { layoutContext } from "@context/layout";
import { historyContext } from "@context/history";
import { navigationContext } from "@context/navigation";
import { notificationContext } from "@context/notifications";

import "@reach/skip-nav/styles.css";
import "@styles/main.scss";

import * as gtag from "@modules/gtag";

Router.events.on("routeChangeComplete", (url) => gtag.pageview(url));

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const config = getConfig();
    const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
    Sentry.init({
        enabled: process.env.NODE_ENV === "production",
        integrations: [
            new RewriteFrames({
                iteratee: (frame) => {
                    frame.filename = frame.filename.replace(distDir, "app:///_next");
                    return frame;
                },
            }),
        ],
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    });
}

class MyApp extends App {
    /* Minimized tracks the open state on a mobile, whereas collapsed focuses on tablets up. We seperate
    them because we need to be able to differentiate. If you expand the nav on a larger screen (e.g. 
    horizontal phone) and you go back to a mobile sized screen then you don't want the nav to be open. */
    state = {
        navCollapsed: undefined,
        navMinimized: undefined,
        navStateOverridden: false,
        history: [],

        // Navigation context
        visibleHistory: [],
        visibleBookmarks: [],

        // Notifications
        notifications: [],
    };

    handleLoadEvent(event) {
        let node = document.querySelector(".preload-transitions");

        node.classList.remove("preload-transitions");
    }

    componentDidMount() {
        const { asPath } = this.props.router;
        const previousSessionUrl = window?.localStorage?.getItem("url");
        this.setState({ history: [previousSessionUrl, asPath] });

        if (previousSessionUrl !== asPath) {
            window.localStorage.setItem("url", asPath);
        }
        /* We don't want animations to show on the initial load so we attach a class to the body which
        blocks all children from transitioning, then once the app has actually loaded we remove it,
        thereby allowing animations */
        //let node = document.querySelector(".preload-transitions");
        window.addEventListener("load", this.handleLoadEvent);
        //setTimeout(() => node.classList.remove("preload-transitions"), 1000);

        if (process.env.NODE_ENV === "production") {
            LogRocket.init("6ndosl/scribe");
            setupLogRocketReact(LogRocket);

            LogRocket.getSessionURL((sessionURL) => {
                Sentry.configureScope((scope) => {
                    scope.setExtra("sessionURL", sessionURL);
                });
            });
        }
    }

    componentDidUpdate() {
        const { history } = this.state;
        const { asPath } = this.props.router;
        const prevUrl = history[history.length - 1];

        if (asPath && prevUrl !== asPath) {
            window.localStorage.setItem("url", asPath);
            this.setState((prevState) => ({ history: [...prevState.history, asPath] }));
        }
    }

    componentWillUnmount() {
        window.removeEventListener("load", this.handleLoadEvent);
    }

    handleNavCollapse = (navCollapsed) => {
        if (typeof navCollapsed === "function") {
            this.setState({ navCollapsed: navCollapsed(this.state.navCollapsed) });
        } else {
            this.setState({ navCollapsed });
        }
    };

    handleNavMinimize = (navMinimized) => {
        if (typeof navMinimized === "function") {
            this.setState({ navMinimized: navMinimized(this.state.navMinimized) });
        } else {
            this.setState({ navMinimized });
        }
    };

    handleNavStateOverride = (navStateOverridden) => {
        this.setState({ navStateOverridden });
    };

    handleHistoryExpand = (visibleHistory) => {
        this.setState({ visibleHistory });
    };

    handleBookmarksExpand = (visibleBookmarks) => {
        this.setState({ visibleBookmarks });
    };

    handleRemoveNotification = (notification) => {
        const newNotifications = this.state.notifications.filter((n) => n.id !== notification.id);
        this.setState({ notifications: newNotifications });
    };

    // This is very simplistic implementation - it won't handle too many notifications at once
    handleAddNotification = (notification) => {
        const notificationWithId = { ...notification, id: notification.id ?? uuidv4() };
        const newNotifications = [...this.state.notifications, notificationWithId].sort((n1, n2) => {
            if (n1.priority && !n2.priority) return -1;
            if (!n1.priority && n2.priority) return 1;
            if (n1.priority && n2.priority) return 0;
        });

        this.setState({ notifications: newNotifications });
    };

    render() {
        const { Component, pageProps } = this.props;
        const {
            navCollapsed,
            navMinimized,
            navStateOverridden,
            history,
            visibleHistory,
            visibleBookmarks,
            notifications,
        } = this.state;

        return (
            <layoutContext.Provider
                value={{
                    navCollapsed,
                    navMinimized,
                    navStateOverridden,
                    onNavCollapse: this.handleNavCollapse,
                    onNavMinimized: this.handleNavMinimize,
                    onNavStateOverride: this.handleNavStateOverride,
                }}
            >
                <navigationContext.Provider
                    value={{
                        visibleHistory,
                        visibleBookmarks,
                        onHistoryExpand: this.handleHistoryExpand,
                        onBookmarksExpand: this.handleBookmarksExpand,
                    }}
                >
                    <historyContext.Provider value={history}>
                        <notificationContext.Provider
                            value={{
                                notifications,
                                notify: this.handleAddNotification,
                                hideNotification: this.handleRemoveNotification,
                            }}
                        >
                            <Component {...pageProps} />
                        </notificationContext.Provider>
                    </historyContext.Provider>
                </navigationContext.Provider>
            </layoutContext.Provider>
        );
    }
}

export default MyApp;
